@import '~styles/_sass-includes.scss';

.header.universal {
  height: 4em;
  position: sticky;
  top: 0;
  z-index: 10002;
  display: flex;
  align-items: center;
  background-color: #1C3564;
  text-transform: uppercase;
  color: $white;

  > .fullrow {
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    width: 100%;
  }

  .search-toggle {
    display: inline-block;
    position: relative;
    color: inherit;

    @include breakpoint($mobile-lg) {
      display: none;
    }
  }
  
  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3em;
    max-width: 136.3rem;
    margin: 0 auto;
    width: 100%;

    @include breakpoint($tablet-md) {
      grid-template-columns: 50px 4fr 1fr !important;
      padding: 0;
    }

    @include breakpoint($mobile-lg) {
      grid-template-columns: 50px minmax(0px, 1fr) 90px !important;
      padding: 0;
    }
  }
  
  .header__logo {
    grid-area: logo;
    position: relative;
    height: 24px !important;
    // media queries
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include breakpoint($tablet-md) {
        display: inline-block;
        text-align: center;
        width: auto;
      }
    }
    a, img {
      height: 100%;
      object-fit: contain;
      vertical-align: middle;
    }
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .header__left {
    display: flex;
    gap: 32px;
    flex-direction: row;
    align-items: center;

    .divider {
      width: 1px;
      height: 29px;
      background: rgba(255, 255, 255, 0.08);
      @include breakpoint($mobile-lg) {
        display: none;
      }
    }
  }

  .header__menuitems { grid-area: menuItems; }

  .header__actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    gap: 12px;

    .socialicons li {
      &:first-of-type { margin-left: 10px; }
      &:last-of-type { margin-right: 10px; }
    }
  }

  .header__hamburger {
    grid-area: hamburger;
    position: relative;
    z-index: 100;
    @include breakpoint($mobile-lg) {
      width: 105px; 
    }
  }
}

.header-notification {
  background: $orange;
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 1.2rem;
  color: $white;
  font-weight: 600;
  line-height: 1.4;
  position: relative;
  z-index: 1000;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
