@import '~styles/sass-includes';

.header__cart {
  position: relative;
  display: inline-block;
  // media queries
  @include breakpoint($mobile-sm) {
    margin-left: 0.5rem;
  }
  @include breakpoint($mobile-lg) {
    margin-left: 0;
  }
  a {
    color: var(--neutral-70) !important;
  }
  .header__cart_button{
    color: $white;
    border: none;
    padding: 10px 14px 10px 12px;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:hover{
      background-color: #E9B732;
      transition: all 0.3s linear;
      color: black;
    }

    .pr_icon{
      display: flex;
      // media queries
      @include breakpoint($mobile-lg, min-width) {
      }
    }
  }

  a {
    color: inherit;
  }
}

@include breakpoint($mobile-lg) {
  .header__cart{
    .header__cart__text {
      display: none;
    }

    .header__cart_button{
      padding: .5em;
    }

    .spr_icon{
      margin: 0;
    }
  }
}
